import styled, { css } from "styled-components";
import { getCSSExternalSpace } from "../../utils/css";

const Typography = styled.p`
  ${({ theme, textStyle }) => theme.textStyles[textStyle] || css``}
  ${({
  margin,
  padding,
  color,
  fontType,
  fontSize,
  fontWeight,
  fontFamily,
  lineHeight,
  textAlign,
  theme,
  display,
}) => css`
    margin: ${getCSSExternalSpace(margin) || "0px 0px 16px 0px"};
    padding: ${getCSSExternalSpace(padding) || "0px"};
    color: ${theme.colors[color] || color || theme.colors.black};
    font-size: ${fontSize || "16px"};
    font-weight: ${fontWeight || "normal"};
    font-family: ${fontFamily || "Montserrat"};
    line-height: ${lineHeight || "1.5"};
    text-align: ${textAlign || "left"};
    display: ${display};
  `}
`;
export const HomeHeading = styled.h1`
  ${({ theme, textStyle }) => theme.textStyles[textStyle] || css``}
  ${({
  margin,
  padding,
  color,
  fontType,
  fontSize,
  fontWeight,
  fontFamily,
  lineHeight,
  textAlign,
  theme,
  display,
}) => css`
    margin: ${getCSSExternalSpace(margin) || "0px 0px 16px 0px"};
    padding: ${getCSSExternalSpace(padding) || "0px"};
    color: ${theme.colors[color] || color || theme.colors.black};
    font-size: ${fontSize || "16px"};
    font-weight: ${fontWeight || "normal"};
    font-family: ${fontFamily || "Montserrat"};
    line-height: ${lineHeight || "1.5"};
    text-align: ${textAlign || "left"};
    display: ${display};
  `}
`;
export const Title = styled(Typography)`
  position: relative;
  margin-bottom: 30px;
  ${({ theme }) => css`
    color: ${theme.colors.darkGray2};
    font-size: 2rem;
    font-weight: bold;
    font-family: ${theme.fonts.header};
  `}
  &:before {
    position: absolute;
    width: 150px;
    height: 5px;
    bottom: -10px;
    background-color: ${(props) => props.theme.colors.darkGray2};
    content: "";
  }
`;
export const BenefitsHeading = styled(Typography)`
  position: relative;
  margin-bottom: 30px;
  ${({ theme }) => css`
    color: ${theme.colors.darkGray2};
    font-size: 2rem;
    font-weight: bold;
    font-family: ${theme.fonts.header};
  `}

`;

export default Typography;
